<template>
  <div>
    <input v-model="newTask.description" placeholder="Add a new task" />
    <select v-model="newTask.category">
      <option value="Ops">Ops</option>
      <option value="HR">HR</option>
      <option value="Business">Business</option>
      <option value="Misc">Misc</option>
    </select>
    <button @click="addNewTask">Add Task</button>
  </div>
  <div>
    <ul>
      <li v-for="(task, index) in tasks" :key="index">
        {{ task.description }} - {{ task.category }}
        <button @click="deleteTask(index)">Delete</button>
        <button @click="editTask(index)">Edit</button>
      </li>
    </ul>
  </div>
</template>

<script>
import store from '@/store/index';


export default {
   data() {
    return {
      
      newTask: {
        description: '',
        category: 'Misc'
      },
      isEditing: false,
      editIndex: -1
    }
  },
  computed: {
    tasks() {
      return store.state['app-utilities'].tasks
    }
  },
  methods: {
    addNewTask() {
      if (this.isEditing) {
        store.dispatch('app-utilities/editTask', {
          taskIndex: this.editIndex,
          description: this.newTask.description
        })
        this.isEditing = false
        this.editIndex = -1
      } else {
        store.dispatch('app-utilities/addTask', { ...this.newTask })
      }
      this.newTask.description = ''
      this.newTask.category = 'Misc'
    },
    deleteTask(index) {
      store.dispatch('app-utilities/deleteTask', index)
    },
    editTask(index) {
      this.newTask = { ...this.tasks[index] }
      this.isEditing = true
      this.editIndex = index
    }
  }
}
</script>